import * as React from "react"
import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "../../components/ui/base/typography"
import Header from "../../components/ui/base/layout/header"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import Section from "../../components/ui/base/Section"
import * as styles from "./index.module.scss"
import { monthWrapper, title } from "./index.module.scss"
import { getCalendar, getLocalizedLinkToMonth } from "../../util/tour"
import SessionCard from "../../components/ui/base/SessionCard"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import classnames from "classnames"
import Link from "../../components/ui/base/Link"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { BASE_URL, CALENDAR_URL, PHOTO_TOURS_URL } from "../../util/constants"

const CalendarMonthPage = data => {
  const { t, i18n: l } = useTranslation()
  const [months, setMonths] = useState(0)
  const tours = data?.data.allStrapiTour.edges
  const staticMonths = getCalendar(tours)
  const headerPicture =
    data.data.allFile.edges[0].node.childImageSharp.gatsbyImageData
  const monthLink = `${BASE_URL[l.language]}/${PHOTO_TOURS_URL[l.language]}/${
    CALENDAR_URL[l.language]
  }/${data.pageContext.year}/${data.pageContext.month}/`

  useEffect(() => {
    fetch(`${process.env.GATSBY_API_URL}/tours`)
      .then(response => response.json())
      .then(resultData => {
        setMonths(
          getCalendar(resultData, data.pageContext.year, data.pageContext.month)
        )
      })
  }, [data.pageContext.month, data.pageContext.month])

  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang: l.language }}
        title={`Viaggi Fotografici ${data.pageContext.month} ${data.pageContext.year}`}
        language={l.language}
        description={`Scopri quali viaggi fotografici abbiamo in calendario per ${data.pageContext.month} ${data.pageContext.year}. Parti con altre persone come te, scatta fotografie da invidia. Prenota Ora `}
        canonical={monthLink}
      />

      <Header size={"compact"} img={headerPicture} theme="dark">
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant="heading1">
                  Viaggi Fotografici {data.pageContext.month}{" "}
                  {data.pageContext.year}
                </Typography>
                <Breadcrumbs
                  elements={[
                    {
                      name: "WeShoot",
                      path: "/",
                    },
                    {
                      name: "Viaggi Fotografici",
                      path: "/viaggi-fotografici/",
                    },
                    {
                      name: "Calendario",
                      path: "/viaggi-fotografici/calendario/",
                    },
                    {
                      name: `${data.pageContext.year}`,
                      path: `/viaggi-fotografici/calendario/${data.pageContext.year}/`,
                    },
                    {
                      name: `${data.pageContext.month}`,
                      path: `/viaggi-fotografici/calendario/${data.pageContext.year}/${data.pageContext.month}/`,
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <Section
        id={"sessions"}
        title={t("templates.calendar.calendar.nextDepartures.title")}
        variant={"grey"}
        subtitle={t("templates.calendar.calendar.nextDepartures.description")}
      >
        {Object.keys(months).length === 0
          ? Object.keys(staticMonths).map((key, i) => {
              let month = staticMonths[key]
              return (
                <div key={key}>
                  <div className={classnames([styles.monthWrapper])}>
                    <h3 className={classnames([styles.title])}>
                      {`${month.month} ${month.year}`}
                    </h3>
                    <div className={classnames([styles.number])}>
                      <Link
                        to={getLocalizedLinkToMonth(l, month.year, month.month)}
                      >
                        {t("templates.calendar.calendar.table.header.showAll", {
                          count: month.tours.length,
                          month: month.month,
                          year: month.year,
                        })}
                      </Link>
                    </div>
                  </div>
                  {month.tours?.map(session => {
                    return (
                      <SessionCard
                        key={session.id}
                        session={session.session}
                        tour={session.tour}
                        directBooking={false}
                        skeleton={false}
                      />
                    )
                  })}
                </div>
              )
            })
          : Object.keys(months).map((key, i) => {
              let month = months[key]
              return (
                <div key={key}>
                  <div className={monthWrapper}>
                    <h3 className={title}>
                      Viaggi Fotografici {`${month.month} ${month.year}`}
                    </h3>
                  </div>
                  {month.tours?.map(session => {
                    return (
                      <SessionCard
                        key={session.session.sessionId}
                        session={session.session}
                        tour={session.tour}
                        directBooking={false}
                      />
                    )
                  })}
                </div>
              )
            })}
      </Section>
    </>
  )
}

export const query = graphql`
  query MonthQuery($month: String!) {
    allStrapiTour(limit: 6, sort: { order: ASC, fields: sessions___start }) {
      ...StrapiTourConnectionFragment
    }
    allFile(filter: { name: { eq: $month } }) {
      edges {
        node {
          ...ImageFragment
        }
      }
    }
  }
`

export default CalendarMonthPage
